import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./detailStyle.css";

const DetailCommande = () => {
  const navigate = useNavigate();
  const [updateStatus, setUpdateStatus] = useState("");
  const [commande, setCommande] = useState({
    pickups: [],
    photo: "https://i.postimg.cc/1zytFbBp/order-photo-sample.jpg",
    delivery: {
      contact: {},
      address: {},
    },
  });

  const { id } = useParams(); // Get the id from the URL

  useEffect(() => {
    const fetchCommande = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `https://api.express.ci/partners/orders/${id}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        setCommande(response.data);
      } catch (error) {
        console.error("Error fetching commande details:", error);
      }
    };

    fetchCommande();
  }, [id]);

  const getBadgeContent = (status) => {
    switch (status) {
      case "pending":
        return { text: "En attente", color: "#F7DA03" };
      case "delivered":
        return { text: "Livré", color: "#2CA447" };
      case "canceled":
        return { text: "Annulé", color: "#DB334B" };
      case "inprocess":
        return { text: "En cours", color: "#047AFA" };
      default:
        return { text: "Inconnu", color: "#6B7580" };
    }
  };

  const handleClick = (status) => {
    const token = localStorage.getItem("token");
    axios
      .put(
        `https://api.express.ci/partners/orders/${id}/status`,
        { status: status },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(() => {
        alert("Le status de la commande a été modifié avec succès");
        window.location.reload();
      })
      .catch((error) => {
        alert(error);
      });
  };

  const badgeContent = getBadgeContent(commande?.status?.status);

  const handleUpdate = () => {
    console.log("=>(Detail.jsx:81) 'commande/edit,id,", "commande/edit", id);
    navigate(`/commande/edit/${id}`);
  };

  return (
    <div className="container">
      <div className="header">
        <div className="header-left">
          <h1>Commande #{commande.uuid}</h1>
          <span
            className="badge"
            style={{ backgroundColor: badgeContent.color }}
          >
            {badgeContent.text}
          </span>
        </div>
        <div className="status-section">
          <span className="status-instructions">
            Mettre à jour la commande :
          </span>
          <div className="status-buttons">
            <div>
              <button
                className={`status-button en-attente m-2 ${
                  updateStatus === "pending" ? "disabled" : ""
                }`}
                onClick={() => handleClick("pending")}
              >
                En attente
              </button>
              <button
                className={`status-button en-cours m-2 ${
                  updateStatus === "inprocess" ? "disabled" : ""
                }`}
                onClick={() => handleClick("inprocess")}
              >
                En cours
              </button>
              <button
                className={`status-button livre m-2 ${
                  updateStatus === "delivered" ? "disabled" : ""
                }`}
                onClick={() => handleClick("delivered")}
              >
                Livré
              </button>
              <button
                className={`status-button annuler m-2 ${
                  updateStatus === "canceled" ? "disabled" : ""
                }`}
                onClick={() => handleClick("canceled")}
              >
                Annulé
              </button>
            </div>
          </div>
          <button
            onClick={handleUpdate}
            className="modify-button status-button"
          >
            Modifier
          </button>
        </div>
      </div>
      <div className="columns mt-3">
        <div className="column">
          <div className="section-header">Informations Générales</div>
          <table>
            <tbody>
              <tr>
                <th># Numéro</th>
                <td>
                  <span>{commande.uuid}</span>
                </td>
              </tr>
              <tr>
                <th>Frais de Livraison</th>
                <td>
                  <span>
                    {commande?.deliveryFee
                      ?.toLocaleString("en", { useGrouping: true })
                      .replace(/,/g, " ")}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Montant Article</th>
                <td>
                  <span>
                    {commande?.codAmount
                      ?.toLocaleString("en", { useGrouping: true })
                      .replace(/,/g, " ")}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Créé le</th>
                <td>
                  <span>{commande.createdAt}</span>
                </td>
              </tr>
              <tr>
                <th>Mis à Jour le</th>
                <td>
                  <span>{commande.updatedAt}</span>
                </td>
              </tr>
              <tr>
                <th>Statut</th>
                <td>
                  <span>{badgeContent.text}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="section-header">Informations de l'Utilisateur</div>
          <table>
            <tbody>
              <tr>
                <th>Nom</th>
                <td>
                  <span>{commande.user?.name}</span>
                </td>
              </tr>
              <tr>
                <th>Téléphone</th>
                <td>
                  <span>{commande.user?.phoneNumber}</span>
                </td>
              </tr>
              <tr>
                <th>WhatsApp</th>
                <td>
                  <span>{commande.user?.whatsapp}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="column">
          <div className="section-header">Lieu de Récupération</div>
          <table>
            <tbody>
              {commande?.pickups?.map((pickup, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th>Nom du contact</th>
                    <td>
                      <span>{pickup?.contact?.name || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Téléphone</th>
                    <td>
                      <span>{pickup?.contact?.phoneNumber || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Adresse</th>
                    <td>
                      <span>{pickup?.address?.name || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Repère</th>
                    <td>
                      <span>{pickup?.address?.landmark || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Ville</th>
                    <td>
                      <span>{pickup?.address?.city || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Taille</th>
                    <td>
                      <span>{pickup?.size || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Poids</th>
                    <td>
                      <span>{pickup?.weight || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Quantité</th>
                    <td>
                      <span>{pickup?.quantity || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Dimensions</th>
                    <td>
                      <span>{pickup?.dimensions || ""}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td>
                      <span>{pickup?.description || ""}</span>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>

        <div className="column">
          <div className="section-header">Détails de la Livraison</div>
          <table>
            <tbody>
              <tr>
                <th>Nom du contact</th>
                <td>
                  <span>{commande.delivery.contact?.name || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Téléphone</th>
                <td>
                  <span>{commande.delivery.contact?.phoneNumber || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Adresse</th>
                <td>
                  <span>{commande.delivery.address?.name || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Repère</th>
                <td>
                  <span>{commande.delivery.address?.landmark || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Ville</th>
                <td>
                  <span>{commande.delivery.address?.city || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Type</th>
                <td>
                  <span>{commande.delivery.type || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Date Limite</th>
                <td>
                  <span>{commande.delivery.deadline || ""}</span>
                </td>
              </tr>
              <tr>
                <th>Instructions</th>
                <td>
                  <span>{commande.delivery.instructions || ""}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Conditional rendering for the photo */}
        {commande?.pickups[0]?.photoUrl ? (
          <div className="photo-column">
            <div className="section-header">Photo de la Commande</div>
            <div className="photo-container">
              <img
                src={commande.pickups[0].photoUrl}
                alt="Photo de la commande"
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DetailCommande;
