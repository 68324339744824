import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CommandesTable from "./CommandesTable";
import CommandeeAnnuleesTable from "./CommandeeAnnuleesTable";
import CommandesEnCourTable from "./CommandesEnCourTable";
import CommandeDelevriedTable from "./CommandeDelevriedTable";
import CommandePendingTable from "./CommandePendingTable";
import axios from "axios";
import "./style.css"; // Importing the CSS module

function ListCommandes() {
  const [commandes, setCommandes] = useState([]);
  const [commandesInProcess, setCommandesInProcess] = useState([]);
  const [canceledCommandes, setCanceledCommandes] = useState([]);
  const [DelevriedCommandes, setDelevriedCommandes] = useState([]);
  const [penddingCommandes, setPenddingCommandes] = useState([]);

  const [loading, setLoading] = useState(true);
  const [searchQueryNum, setSearchQueryNum] = useState(""); // General search query
  const [searchQueryTel, setSearchQueryTel] = useState(""); // Search by phone number
  const [searchQueryAddress, setSearchQueryAddress] = useState(""); // Search by address
  const [searchQueryStatus, setSearchQueryStatus] = useState(""); // Search by status
  const [startDate, setStartDate] = useState(""); // Start date for filtering
  const [endDate, setEndDate] = useState(""); // End date for filtering

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(
          "https://api.express.ci/partners/orders",
          {
            headers: {
              Authorization: "Bearer " + token,
            },
            params: {
              startDate: startDate,
              endDate: endDate,
            },
          }
        );
        const data = response.data;

        const allCommandes = data.data;
        setCommandes(allCommandes);
        setCommandesInProcess(
          allCommandes.filter((order) => order.status.status === "inprocess")
        );
        setCanceledCommandes(
          allCommandes.filter((order) => order.status.status === "canceled")
        );
        setDelevriedCommandes(
          allCommandes.filter((order) => order.status.status === "delivered")
        );
        setPenddingCommandes(
          allCommandes.filter((order) => order.status.status === "pending")
        );

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]); // Add startDate and endDate as dependencies

  const handleSearchChangeNum = (event) => {
    setSearchQueryNum(event.target.value);
  };

  const handleSearchChangeTel = (event) => {
    setSearchQueryTel(event.target.value.toLowerCase());
  };

  const handleSearchChangeStatus = (event) => {
    setSearchQueryStatus(event.target.value.toLowerCase());
  };

  const filterCommandes = (commandes) => {
    return commandes.filter((commande) => {
      const status = commande.status?.status.toLowerCase() || "";
      const createdDate = new Date(commande.createdAt); // Assuming 'createdAt' is the date field
      const start = new Date(startDate);
      const end = new Date(endDate);

      return (
        (searchQueryTel === "" ||
          commande?.delivery?.contact?.phoneNumber.includes(searchQueryTel) ||
          commande?.user?.phoneNumber.includes(searchQueryTel) ||
          commande?.pickups?.contact?.phoneNumber.includes(searchQueryTel)) &&
        (searchQueryAddress === "" ||
          addressName.includes(searchQueryAddress)) &&
        (searchQueryStatus === "" || status.includes(searchQueryStatus)) &&
        (searchQueryNum === "" || commande.uuid.includes(searchQueryNum)) &&
        (isNaN(start.getTime()) || createdDate >= start) &&
        (isNaN(end.getTime()) || createdDate <= end)
      );
    });
  };

  const filteredCommandes = filterCommandes(commandes);
  const filteredCommandesInProcess = filterCommandes(commandesInProcess);
  const filteredCanceledCommandes = filterCommandes(canceledCommandes);
  const filtereddelevriedCommandes = filterCommandes(DelevriedCommandes);
  const filteredpenddingCommandes = filterCommandes(penddingCommandes);

  if (loading) {
    return <div></div>;
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-center gap-4">
        <form className="form-inline d-flex justify-content-center gap-4">
          <input
            type="text"
            className="form-control"
            placeholder="N° commande"
            value={searchQueryNum}
            onChange={handleSearchChangeNum}
          />
          <input
            type="text"
            className="form-control "
            placeholder="Téléphone"
            value={searchQueryTel}
            onChange={handleSearchChangeTel}
          />
          <select
            className="form-control "
            value={searchQueryStatus}
            onChange={handleSearchChangeStatus}
          >
            <option value="" disabled>
              {" "}
              Status{" "}
            </option>
            <option value="inprocess">En cours</option>
            <option value="delivered">Livré</option>
            <option value="pending">En attente</option>
            <option value="canceled">Annulé</option>
          </select>
          <input
            type="date"
            className="form-control "
            placeholder="Date de début..."
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <input
            type="date"
            className="form-control "
            placeholder="Date de fin..."
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </form>
        <div className="">
          <a href="/add-commandes" className="btn  btn-success">
            créer une commande
          </a>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-10"></div>
        <div className="col-md-2"></div>
        <Tabs
          defaultActiveKey="list-commandes"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="commandes-en-attente"
            title={`Commandes en attente (${filteredpenddingCommandes.length})`}
          >
            <CommandePendingTable commandes={filteredpenddingCommandes} />
          </Tab>
          <Tab
            eventKey="commandes-en-cours"
            title={`Commandes en cours (${filteredCommandesInProcess.length})`}
          >
            <CommandesEnCourTable commandes={filteredCommandesInProcess} />
          </Tab>
          <Tab
            eventKey="commandes-delevried"
            title={`Commandes en livrées (${filtereddelevriedCommandes.length})`}
          >
            <CommandeDelevriedTable commandes={filtereddelevriedCommandes} />
          </Tab>
          <Tab
            eventKey="commandes-annulees"
            title={`Commandes annulées (${filteredCanceledCommandes.length})`}
          >
            <CommandeeAnnuleesTable commandes={filteredCanceledCommandes} />
          </Tab>
          <Tab
            eventKey="list-commandes"
            title={`Historique commandes (${filteredCommandes.length})`}
          >
            <CommandesTable commandes={filteredCommandes} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default ListCommandes;
