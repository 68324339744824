



// // import React, { useState } from 'react';
// // import Table from 'react-bootstrap/Table';
// // import Pagination from 'react-bootstrap/Pagination';
// // import Badge from 'react-bootstrap/Badge'; // Import Badge component

// // function CommandeeAnnuleesTable({ commandes }) {
// //     console.log('Commandes:', commandes); // Add this line to check the value of commandes

// //     const [currentPage, setCurrentPage] = useState(1);
// //     const itemsPerPage = 10; // Number of items per page

// //     if (!Array.isArray(commandes)) {
// //         return <div>Invalid data format</div>; // Display error message if commandes is not an array
// //     }

// //     // Calculate the index of the first and last item to display
// //     const indexOfLastItem = currentPage * itemsPerPage;
// //     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
// //     const currentItems = commandes.slice(indexOfFirstItem, indexOfLastItem);

// //     // Calculate the total number of pages
// //     const totalPages = Math.ceil(commandes.length / itemsPerPage);

// //     // Function to handle page change
// //     const handlePageChange = (pageNumber) => {
// //         setCurrentPage(pageNumber);
// //     };

// //     // Function to determine badge variant based on status
// //     const getBadgeVariant = (status) => {
// //         switch (status) {
// //             case 'Pending':
// //                 return 'warning'; // Yellow badge for pending orders
// //             case 'Delivered':
// //                 return 'success'; // Green badge for delivered orders
// //             case 'In Process':
// //                 return 'info'; // Blue badge for orders in process
// //             case 'Cancelled':
// //                 return 'danger'; // Red badge for cancelled orders
// //             default:
// //                 return 'secondary'; // Gray badge for unknown status
// //         }
// //     };

// //     return (
// //         <div>
// //             <Table striped bordered hover variant="light">
// //                 <thead>
// //                     <tr>
    
// //                         <th>Status</th>
// //                         <th>Date</th>
// //                         <th>Numéro</th>
// //                         <th>Récuperation</th>
// //                         <th>Téléphone</th>
// //                         <th>Livraison</th>

// //                         <th>Téléphone</th>
// //                         <th>Montants</th>
// //                         <th>Action</th>
// //                     </tr>
// //                 </thead>
// //                 <tbody>
// //                     {currentItems.length === 0 ? (
// //                         <tr>
// //                             <td colSpan={9} className="text-center">Pas de commande disponible</td>
// //                         </tr>
// //                     ) : (
// //                         currentItems.map((commande, index) => (
// //                             <tr key={commande.id}>
// //                                 {/* <td>{index + 1 + indexOfFirstItem}</td>
// //                                 <td>{commande.uuid}</td>
// //                                 <td>{commande.delivery.address.name}</td> */}
// //                                 <td>
// //                                     <Badge pill variant={getBadgeVariant(commande.status.status)}>
// //                                         {
// //                                             commande.status.status === 'pending' ? 'En attente' :
// //                                                 commande.status.status === 'delivered' ? 'Envoyées' :
// //                                                     commande.status.status === 'canceled' ? 'Annulé' :
// //                                                         commande.status.status === 'inprocess' ? 'En cours' :  // Adjust the text if 'Annulé' is not correct for 'inprocess'
// //                                                             ''
// //                                         }                         </Badge>
// //                                 </td>
// //                                 <td>{new Date(commande.createdAt).toLocaleDateString()}</td>
// //                                 <td>{commande.uuid}</td>

// //                                 <td>{commande.pickups[0].address.city}</td>
// //                                 <td>
// //                                     <td>{commande.pickups[0].contact.phoneNumber}</td>
// //                                 </td>
// //                                 <td>{commande.delivery.address.city}</td>
// //                                 <td>{commande.delivery.contact.phoneNumber}</td>
// //                                 <td>{commande.codAmount}</td>
// //                                 <td>
// //                                 <a className='mr-3 btn btn-success btn-sm' href={`/commande/${commande.id}`}>
// //                                         consulter
// //                                     </a>
// //                                     <a className='btn btn-primary btn-sm' href={`/commande/edit/${commande.id}`}>
// //                                         modifier
// //                                     </a>
// //                                 </td>
// //                             </tr>
// //                         ))
// //                     )}
// //                 </tbody>
// //             </Table>

// //             <div className="d-flex justify-content-center mt-3">
// //                 <Pagination>
// //                     <Pagination.Prev
// //                         onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
// //                         disabled={currentPage === 1}
// //                     />
// //                     {[...Array(totalPages).keys()].map((number) => (
// //                         <Pagination.Item
// //                             key={number + 1}
// //                             active={number + 1 === currentPage}
// //                             onClick={() => handlePageChange(number + 1)}
// //                         >
// //                             {number + 1}
// //                         </Pagination.Item>
// //                     ))}
// //                     <Pagination.Next
// //                         onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
// //                         disabled={currentPage === totalPages}
// //                     />
// //                 </Pagination>
// //             </div>
// //         </div>
// //     );
// // }

// // export default CommandeeAnnuleesTable;



// import React, { useState } from 'react';
// import Table from 'react-bootstrap/Table';
// import Pagination from 'react-bootstrap/Pagination';

// function CommandeeAnnuleesTable({ commandes }) {

//     const [currentPage, setCurrentPage] = useState(1);
//     const itemsPerPage = 100; // Number of items per page

//     if (!Array.isArray(commandes)) {
//         return <div>Invalid data format</div>; // Display error message if commandes is not an array
//     }

//     // Calculate the index of the first and last item to display
//     const indexOfLastItem = currentPage * itemsPerPage;
//     const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//     const currentItems = commandes.slice(indexOfFirstItem, indexOfLastItem);

//     // Calculate the total number of pages
//     const totalPages = Math.ceil(commandes.length / itemsPerPage);

//     // Function to handle page change
//     const handlePageChange = (pageNumber) => {
//         setCurrentPage(pageNumber);
//     };

//     // Function to determine badge content based on status
//     const getBadgeContent = (status) => {
//         switch (status) {
//             case 'pending':
//                 return { text: 'En attente', color: '#ffd700' }; // Gold color for pending
//             case 'delivered':
//                 return { text: 'Livré', color: '#28a745 ' }; // Green color for delivered
//             case 'canceled':
//                 return { text: 'Annulé', color: '#dc3545 ' }; // Red color for canceled
//             case 'inprocess':
//                 return { text: 'En cours', color: '#17a2b8 ' }; // Blue color for in process
//             default:
//                 return { text: '', color: '#6c757d ' }; // Gray color for unknown status
//         }
//     };

//     return (
//         <div>
//             <Table striped bordered hover variant="light">
//                 <thead>
//                     <tr>
//                         <th>Status</th>
//                         <th>Date</th>
//                         <th>Numéro</th>
//                         <th>Récuperation</th>
//                         <th>Téléphone</th>
//                         <th>Livraison</th>
//                         <th>Téléphone</th>
//                         <th>Frais Livraison</th>

//                         <th>Montant Article</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {currentItems.length === 0 ? (
//                         <tr>
//                             <td colSpan={9} className="text-center">No orders available</td>
//                         </tr>
//                     ) : (
//                         currentItems.map((commande) => {
//                             const badgeContent = getBadgeContent(commande.status.status);

//                             return (
//                                 <tr key={commande.id}>
//                                     <td>
//                                         <span class="badge"  style={{ backgroundColor: badgeContent.color }}>
//                                             {badgeContent.text}
//                                         </span>

//                                     </td>
//                                     <td>{new Date(commande.createdAt).toLocaleDateString()}</td>
//                                     <td>{commande.uuid}</td>
//                                     <td>{commande.pickups[0].address.city}</td>
//                                     <td>{commande.pickups[0].contact.phoneNumber}</td>
//                                     <td>{commande.delivery.address.city}</td>
//                                     <td>{commande.delivery.contact.phoneNumber}</td>
//                                     <td>{commande.deliveryFee}</td>

//                                     <td>{commande.codAmount}</td>
//                                     <td className='m-3'>
//                                     <a
//                                             className="mr-4 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
//                                             href={`/commande/${commande.id}`}
//                                             style={{ marginRight: '10%' }} // Corrected style syntax

//                                         >
//                                             Consulter
//                                         </a>
//                                         <a
//                                             className="mr-4 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
//                                             href={`/commande/edit/${commande.id}`}
//                                         >
//                                             Modifier
//                                         </a>
//                                     </td>
//                                 </tr>
//                             );
//                         })
//                     )}
//                 </tbody>
//             </Table>

//             <div className="d-flex justify-content-center mt-3">
//                 <Pagination>
//                     <Pagination.Prev
//                         onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
//                         disabled={currentPage === 1}
//                     />
//                     {[...Array(totalPages).keys()].map((number) => (
//                         <Pagination.Item
//                             key={number + 1}
//                             active={number + 1 === currentPage}
//                             onClick={() => handlePageChange(number + 1)}
//                         >
//                             {number + 1}
//                         </Pagination.Item>
//                     ))}
//                     <Pagination.Next
//                         onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
//                         disabled={currentPage === totalPages}
//                     />
//                 </Pagination>
//             </div>
//         </div>
//     );
// }

// export default CommandeeAnnuleesTable;


import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

function CommandeeAnnuleesTable({ commandes }) {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 100; // Number of items per page

    if (!Array.isArray(commandes)) {
        return <div>Invalid data format</div>; // Display error message if commandes is not an array
    }

    // Calculate the index of the first and last item to display
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = commandes.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the total number of pages
    const totalPages = Math.ceil(commandes.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Function to determine badge content based on status
    const getBadgeContent = (status) => {
        switch (status) {
            case 'pending':
                return { text: 'En attente', color: '#ffd700' }; // Gold color for pending
            case 'delivered':
                return { text: 'Livré', color: '#28a745' }; // Green color for delivered
            case 'canceled':
                return { text: 'Annulé', color: '#dc3545' }; // Red color for canceled
            case 'inprocess':
                return { text: 'En cours', color: '#17a2b8' }; // Blue color for in process
            default:
                return { text: 'Inconnu', color: '#6c757d' }; // Gray color for unknown status
        }
    };

    return (
        <div>
            <Table striped  hover variant="light">
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Numéro</th>
                        <th>Récuperation</th>
                        <th>Téléphone</th>
                        <th>Livraison</th>
                        <th>Téléphone</th>
                        <th>Frais de livraison</th>
                        <th>Montant Article</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.length === 0 ? (
                        <tr>
                            <td colSpan={10} className="text-center">No orders available</td>
                        </tr>
                    ) : (
                        currentItems.map((commande) => {
                            const badgeContent = getBadgeContent(commande.status.status);

                            return (
                                <tr key={commande.id}>
                                    <td>
                                       <span className="badge" style={{
                                           color: commande.status.status === 'pending' ? '#000000' : '#FFF',
                                           backgroundColor: badgeContent.color
                                       }}>

                                            {badgeContent.text}
                                        </span>
                                    </td>
                                    <td>{new Date(commande.createdAt).toLocaleDateString()}</td>
                                    <td>{commande.uuid}</td>
                                    <td>{commande.pickups[0]?.address.city}</td>
                                    <td>{commande.pickups[0]?.contact.phoneNumber}</td>
                                    <td>{commande.delivery?.address.city}</td>
                                    <td>{commande.delivery?.contact.phoneNumber}</td>
                                    <td>{commande.deliveryFee.toLocaleString('en', { useGrouping: true }).replace(/,/g, ' ')}</td>
                                    <td>{commande.codAmount.toLocaleString('en', { useGrouping: true }).replace(/,/g, ' ')}</td>
                                    <td className='m-3'>
                                        <a
                                            className="mr-4 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                            href={`/commande/${commande.id}`}
                                            style={{ marginRight: '10%' }}
                                        >
                                            Consulter
                                        </a>
                                        <a
                                            className="mr-4 link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                                            href={`/commande/edit/${commande.id}`}
                                        >
                                            Modifier
                                        </a>
                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>

            <div className="d-flex justify-content-center mt-3">
                <Pagination>
                    <Pagination.Prev
                        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    />
                    {[...Array(totalPages).keys()].map((number) => (
                        <Pagination.Item
                            key={number + 1}
                            active={number + 1 === currentPage}
                            onClick={() => handlePageChange(number + 1)}
                        >
                            {number + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    />
                </Pagination>
            </div>
        </div>
    );
}

export default CommandeeAnnuleesTable;



